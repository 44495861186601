var productFeature={
    "1":{
        product_id:167,
        frequency:30,
        time:0,
    },
    "2":{
        product_id:167,
        frequency:120,
        time:0,
    },
    "3":{
        product_id:167,
        frequency:500,
        time:0,
    },
    "4":{
        product_id:167,
        frequency:1000,
        time:0,
    },
    "5":{
        product_id:167,
        frequency:2500,
        time:0,
    },
    "6":{
        product_id:167,
        frequency:5000,
        time:0,
    },
    "7":{
        product_id:167,
        frequency:10000,
        time:0,
    },
    "8":{
        product_id:167,
        frequency:1,
        time:1,
    },
    "9":{
        product_id:167,
        frequency:10,
        time:1,
    },
    "10":{
        product_id:167,
        frequency:100,
        time:1,
    },
    "11":{
        product_id:167,
        frequency:250,
        time:1,
    },
    "12":{
        product_id:167,
        frequency:600,
        time:1,
    },
    "13":{
        product_id:167,
        frequency:1500,
        time:1,
    },
    "14":{
        product_id:167,
        frequency:5000,
        time:1,
    },
    "15":{
        product_id:167,
        frequency:100,
        time:0,
    },
    "16":{
        product_id:167,
        frequency:250,
        time:0,
    },
    "17":{
        product_id:167,
        frequency:30,
        time:1,
    },
    "18":{
        product_id:167,
        frequency:500,
        time:1,
    },
    "19":{
        product_id:167,
        frequency:1000,
        time:1,
    },
    "20":{
        product_id:167,
        frequency:2500,
        time:1,
    },

    "21":{
        product_id:173,
        frequency:100,
        time:0,
    },
    "22":{
        product_id:173,
        frequency:300,
        time:0,
    },
    "23":{
        product_id:173,
        frequency:500,
        time:0,
    },
    "24":{
        product_id:173,
        frequency:1000,
        time:0,
    },
    "25":{
        product_id:173,
        frequency:2500,
        time:0,
    },
    "26":{
        product_id:173,
        frequency:5000,
        time:0,
    },
    "27":{
        product_id:173,
        frequency:100,
        time:1,
    },
    "28":{
        product_id:173,
        frequency:300,
        time:1,
    },
    "29":{
        product_id:173,
        frequency:500,
        time:1,
    },
    "30":{
        product_id:173,
        frequency:1000,
        time:1,
    },
    "31":{
        product_id:173,
        frequency:2500,
        time:1,
    },
    "32":{
        product_id:173,
        frequency:5000,
        time:1,
    },
    "33":{
        product_id:167,
        frequency:300,
        time:0,
    },
    "34":{
        product_id:167,
        frequency:300,
        time:1,
    },
    "35":{
        product_id:178,
        frequency:100,
        time:0,
    },
    "36":{
        product_id:178,
        frequency:300,
        time:0,
    },
    "37":{
        product_id:178,
        frequency:500,
        time:0,
    },
    "38":{
        product_id:178,
        frequency:1000,
        time:0,
    },
    "39":{
        product_id:178,
        frequency:5000,
        time:0,
    },
    "40":{
        product_id:178,
        frequency:100,
        time:1,
    },
    "41":{
        product_id:178,
        frequency:300,
        time:1,
    },
    "42":{
        product_id:178,
        frequency:500,
        time:1,
    },
    "43":{
        product_id:178,
        frequency:1000,
        time:1,
    },
    "44":{
        product_id:178,
        frequency:5000,
        time:1,
    },
    "45":{
        product_id:179,
        frequency:50,
        time:0,
    },
    "46":{
        product_id:179,
        frequency:200,
        time:0,
    },
    "47":{
        product_id:179,
        frequency:500,
        time:0,
    },
    "48":{
        product_id:179,
        frequency:1000,
        time:0,
    },
    "49":{
        product_id:179,
        frequency:2000,
        time:0,
    },
    "50":{
        product_id:179,
        frequency:50,
        time:1,
    },
    "51":{
        product_id:179,
        frequency:200,
        time:1,
    },
    "52":{
        product_id:179,
        frequency:500,
        time:1,
    },
    "53":{
        product_id:179,
        frequency:1000,
        time:1,
    },
    "54":{
        product_id:179,
        frequency:2000,
        time:1,
    },
};