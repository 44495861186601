// $.post(
//     post_url+"product/trial",
//     {
//         e_id:JSON.parse(getCookie('user')).e_id,
//         t_id:JSON.parse(getCookie('user')).t_id,
//         token:JSON.parse(getCookie('user')).token,
//         p_id:179
//     },
//     function(data){
//     }
// )
//查询订单
var orderList=null;
$.ajaxSettings.async = false;
$.post(post_url+"order/list2",{
    e_id:JSON.parse(getCookie('user')).e_id,
    t_id:JSON.parse(getCookie('user')).t_id,
    token:JSON.parse(getCookie('user')).token
},function(data){
    console.log(data);
    error_fn(data.error)
    switch(data.error){
        case 0:
            if(data.orders.length!=0){
                data.orders.sort((a,b)=>b.id-a.id);
                for(var i=0;i<data.orders.length;i++){
                    data.orders[i].payments.sort((a,b)=>b.ts-a.ts);
                }
            }
            orderList=data.orders
        break;
    case 27001:
        break;
}})
$.ajaxSettings.async = true;
console.log(orderList)
var orderList167=[]
var orderList173=[]
var orderList178=[]
var orderList179=[]
for(var i=0;i<orderList.length;i++){
    console.log(orderList[i].plan_id,productFeature[orderList[i].plan_id])
    switch(productFeature[orderList[i].plan_id].product_id){
        case 167:
            orderList167.push(orderList[i])
            break;
        case 173:
            orderList173.push(orderList[i])
            break;
        case 178:
            orderList178.push(orderList[i])
            break;
        case 179:
            orderList179.push(orderList[i])
            break;
    }
}
$.post(post_url+'product/usage',{
    e_id:JSON.parse(getCookie('user')).e_id,
    t_id:JSON.parse(getCookie('user')).t_id,
    token:JSON.parse(getCookie('user')).token,
},function(data){
    console.log(data);
    $('.info>.right .app>.loading').remove();
    error_fn(data.error)
    switch(data.error){
        case 0:
            //改为自己要用的数据
            var productobj=new Object();
            for(var i=0;i<data.products.length;i++){
                productobj[data.products[i].p_id]=[]
                for(var j=0;j<data.products[i].codes.length;j++){
                    productobj[data.products[i].p_id].push({'date':data.products[i].codes[j].expire,'status':data.products[i].codes[j].status});
                };
            };
            //没有数据
            if(JSON.stringify(productobj) =='{}'){
                $('.info .app').append(`
                    <div class="no">
                        <img src="/images/nothing.png" alt="Nothing">
                        <p>${i18n[lang].no_app_found}</p>
                    </div>
                `);
            }else{
                //循环数据
                $.ajaxSettings.async=false
                for(var id in productobj){
                    if(productJSON[id]==undefined){
                        continue;
                    }
                    if(productJSON[id][lang]==undefined){
                        var product=productJSON[id]['en']
                    }else{
                        var product=productJSON[id][lang]
                    }
                    if(product){
                        var order_product='';
                            //判断产品是否免费
                            if(product['isFree']==1){
                                order_product+=`
                                <table>
                                    <tr>
                                        <td>${i18n[lang].plan} 1</td>
                                        <td>${i18n[lang].free_no_limit}</td>
                                        <td></td>
                                    </tr>
                                </table>
                                <div class="more_box">
                                <a href="${product['link']}">${i18n[lang].product_page}</a>
                                </div>
                                `;
                                $('.info .app').append(`
                                    <div class="flex_box">
                                        <div class="left">
                                            <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                            <span>${product['name']}</span>
                                        </div>
                                        <div class="right">
                                            ${order_product}
                                        </div>
                                    </div>
                                `);
                             }else if(product['isFree']==0){
                                if(productobj[id].length){
                                    // 注册码的状态
                                    var productobjStatus=[];
                                    for(var i=0;i<productobj[id].length;i++){
                                        productobj[id][i]['status'].sort((a, b) => a - b);
                                        if(productobj[id][i]['status'][0]==4103){
                                            productobjStatus.push({'status':1});
                                        }else{
                                            productobjStatus.push({'status':productobj[id][i]['status'][0],'date':productobj[id][i]['date']});
                                        }
                                    };
                                    //正常
                                    var productobjVal_1=1;
                                    //过期
                                    var productobjVal_2=1;
                                    //禁用，退款
                                    var productobjVal_3=1;
                                    productobjStatus.sort((a,b)=>a.status-b.status);
                                    for(var i=0;i<productobjStatus.length;i++){
                                        if(id==24){
                                            console.log(productobjStatus[i]['status']);
                                        }
                                        if(productobjStatus[i]['status']==0){
                                            productobjVal_1=0;
                                            order_product+=`
                                            <table>
                                                <tr>
                                                    <td>${i18n[lang].plan} ${i+1}</td>
                                                    <td>${surplusTime(productobjStatus[i]['date'])}</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                            `
                                        }else if(productobjStatus[i]['status']==1&&productobjVal_1&&productobjVal_2){
                                            productobjVal_2=0;
                                            order_product+=`
                                            <table>
                                                <tr>
                                                    <td>${i18n[lang].plan} ${i+1}</td>
                                                    <td style="color: #D80303;">${i18n[lang].expired}</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                            `
                                        }else if(productobjStatus[i]['status']==4101&&productobjVal_1&&productobjVal_3){
                                            productobjVal_3=0;
                                            order_product+=`
                                            <table>
                                                <tr>
                                                    <td>${i18n[lang].plan} ${i+1}</td>
                                                    <td status="4101">${i18n[lang].unregistered}</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                            `
                                        }else if(productobjStatus[i]['status']==3001&&productobjVal_1&&productobjVal_3){
                                            productobjVal_3=0;
                                            order_product+=`
                                            <table>
                                                <tr>
                                                    <td>${i18n[lang].plan} ${i+1}</td>
                                                    <td status="3001">${i18n[lang].unregistered}</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                            `
                                        }
                                    };
                                    //判断是正常还是过期
                                    if(!productobjVal_1){
                                        order_product+=`
                                        <div class="more_box">
                                        <a class="btn" href="${product['buy-link']}">${i18n[lang].buy_more}</a>
                                        <a href="${product['link']}">${i18n[lang].product_page}</a>
                                        </div>`;
                                        $('.info .app').append(`
                                            <div class="flex_box">
                                                <div class="left">
                                                    <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                    <span>${product['name']}</span>
                                                    <img src="/images/member.svg" loading="lazy" alt="Member">
                                                    
                                                </div>
                                                <div class="right">
                                                    ${order_product}
                                                </div>
                                            </div>
                                        `);
                                    }else{
                                        order_product+=`
                                        <div class="again_box">
                                        <a class="btn" href="${product['buy-link']}">${i18n[lang].buy_again}</a>
                                        <a href="${product['link']}">${i18n[lang].product_page}</a>
                                        </div>`;
                                        $('.info .app').append(`
                                            <div class="flex_box">
                                                <div class="left">
                                                    <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                    <span>${product['name']}</span>
                                                    <img src="/images/non-member.svg" loading="lazy" alt="No Member">
                                                    
                                                </div>
                                                <div class="right">
                                                    ${order_product}
                                                </div>
                                            </div>
                                        `);
                                    }
                                }else{
                                    if(product['buy-link']){
                                        order_product+=`
                                        <table>
                                            <tr>
                                                <td>${i18n[lang].plan} 1</td>
                                                <td status="nocode">${i18n[lang].unregistered}</td>
                                                <td></td>
                                            </tr>
                                        </table>
                                        <div class="again_box">
                                        <a class="btn" href="${product['buy-link']}">${i18n[lang].buy_now}</a>
                                        <a href="${product['link']}">${i18n[lang].product_page}</a>
                                        </div>
                                        `;
                                    }else{
                                        order_product+=`
                                        <table>
                                            <tr>
                                                <td>${i18n[lang].plan} 1</td>
                                                <td status="nocode">${i18n[lang].unregistered}</td>
                                                <td></td>
                                            </tr>
                                        </table>
                                        `;
                                    }
                                    $('.info .app').append(`
                                        <div class="flex_box">
                                            <div class="left">
                                                <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                <span>${product['name']}</span>
                                                <img src="/images/non-member.svg" loading="lazy" alt="No Member">
                                                
                                            </div>
                                            <div class="right">
                                                ${order_product}
                                            </div>
                                        </div>
                                    `);
                                }
                            }else if(product['isFree']==2){
                                if(id==167 || id==173 || id==178){
                                    (function(id){
                                        var benefits_ind;
                                        switch(id){
                                            case '167':
                                                benefits_ind=3;
                                                break;
                                            case '173':
                                                benefits_ind=4;
                                                break;
                                            case '178':
                                                benefits_ind=0;
                                                break;
                                        }
                                        var order_product='';
                                        // 查询权益
                                        $.post(
                                            post_url+"benefit/status",
                                            {
                                                e_id:JSON.parse(getCookie('user')).e_id,
                                                t_id:JSON.parse(getCookie('user')).t_id,
                                                token:JSON.parse(getCookie('user')).token,
                                                product_id:id
                                            },function(data){
                                                error_fn(data.error)
                                                console.log(data)
                                                if(productJSON[id][lang]==undefined){
                                                    var product=productJSON[id]['en']
                                                }else{
                                                    var product=productJSON[id][lang]
                                                }
                                                var tempOrderList
                                                switch(data.error){
                                                    case 0:
                                                        if(id==167){
                                                            order_product+="<p style='font-size:16px;color:#333;margin-bottom:16px;'>"+i18n[lang].hd_downloading+"</p><div class='scroll_box'>";
                                                            tempOrderList=orderList167;
                                                        }else if(id==173){
                                                            order_product+="<p style='font-size:16px;color:#333;margin-bottom:16px;'>"+i18n[lang].image_upscaler_p+"</p><div class='scroll_box'>";
                                                            tempOrderList=orderList173;
                                                        }else{
                                                            order_product+="<p style='font-size:16px;color:#333;margin-bottom:16px;'>"+i18n[lang].hd_downloading+"</p><div class='scroll_box'>";
                                                            tempOrderList=orderList178;
                                                        }
                                                        $('.info>.right .app>.loading').remove();
                                                        var totalM=0;
                                                        var useM=0;
                                                        var total=0;
                                                        var use=0;
                                                        var islifetime=0;
                                                        for(var i=0;i<data.subscriptions.length;i++){
                                                            if(data.subscriptions[i].expire!=0){
                                                                if(data.subscriptions[i].benefits[benefits_ind].quota*1>data.subscriptions[i].benefits[benefits_ind].usage&&data.subscriptions[i].benefits[benefits_ind].quota-data.subscriptions[i].benefits[benefits_ind].usage!=0){
                                                                    if(data.subscriptions[i].expire*1>Date.parse(new Date())/1000){
                                                                        order_product+=`
                                                                        <table class="isfree2">
                                                                            <tr>
                                                                                <td>${data.subscriptions[i].benefits[benefits_ind].quota} ${i18n[lang].images}/${i18n[lang].Month} (${getdate(data.subscriptions[i].since)} - ${getdate(data.subscriptions[i].expire)})</td>
                                                                                <td>${i18n[lang].Remaining} <span><i class="num">${data.subscriptions[i].benefits[benefits_ind].quota-data.subscriptions[i].benefits[benefits_ind].usage}</i> ${i18n[lang].images}</span></td>
                                                                            </tr>
                                                                        </table>
                                                                        `;
                                                                    }
                                                                    try{
                                                                        totalM+=data.subscriptions[i].benefits[benefits_ind].quota
                                                                        useM+=data.subscriptions[i].benefits[benefits_ind].usage
                                                                    }catch(e){
                                                                        console.log(e)
                                                                    }
                                                                }
                                                            }else{
                                                                if(data.subscriptions[i].product_feature==0){
                                                                    var isShow=true
                                                                    if(data.subscriptions[i].benefits[benefits_ind].quota*1-data.subscriptions[i].benefits[benefits_ind].usage*1==0){
                                                                        isShow=false
                                                                    }
                                                                    if(tempOrderList.length==0){
                                                                        var isShow=true
                                                                    }
                                                                    if(isShow){
                                                                        order_product+=`
                                                                        <table class="isfree2">
                                                                            <tr>
                                                                                <td>${data.subscriptions[i].benefits[benefits_ind].quota*1} ${i18n[lang].images}/${i18n[lang].lifetime}<i class="free">Free</i></td>
                                                                                <td>${i18n[lang].Remaining} <span><i class="num">${data.subscriptions[i].benefits[benefits_ind].quota*1-data.subscriptions[i].benefits[benefits_ind].usage*1}</i> ${i18n[lang].images}</span></td>
                                                                            </tr>
                                                                        </table>
                                                                        `;
                                                                        total+=data.subscriptions[i].benefits[benefits_ind].quota*1
                                                                        use+=data.subscriptions[i].benefits[benefits_ind].usage*1
                                                                    }
                                                                }else{
                                                                    islifetime=1;
                                                                    try{
                                                                        total+=data.subscriptions[i].benefits[benefits_ind].quota*1
                                                                        use+=data.subscriptions[i].benefits[benefits_ind].usage*1
                                                                    }catch(e){
                                                                        console.log(e)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        console.log(total-use);
                                                        if(islifetime&&total-use!=0){
                                                            order_product+=`
                                                            <table class="isfree2">
                                                                <tr>
                                                                    <td>${total} ${i18n[lang].images}/${i18n[lang].lifetime}</td>
                                                                    <td>${i18n[lang].Remaining} <span><i class="num">${total-use}</i> ${i18n[lang].images}</span></td>
                                                                </tr>
                                                            </table>
                                                            `;
                                                        }
                                                        if(total-use==0&&totalM-useM==0&&tempOrderList.length!=0){
                                                            total=productFeature[tempOrderList[0].plan_id].frequency
                                                            
                                                            order_product+=`
                                                            <table class="isfree2 ${productFeature[tempOrderList[0].plan_id].time?'':'expire'}">
                                                                <tr>
                                                                    <td>${total} ${i18n[lang].credit}/${productFeature[tempOrderList[0].plan_id].time?i18n[lang].lifetime:i18n[lang].Month}</td>
                                                                    <td>${i18n[lang].Remaining} <span><i class="num">0</i> ${i18n[lang].credit}</span></td>
                                                                </tr>
                                                            </table>
                                                            `;
                                                        }
                                                        order_product+=`</div>
                                                        <div class="more_box">
                                                            <a class="btn" href="${product['buy-link']}" target="_blank">${i18n[lang].buy_more}</a>
                                                            <a style="color:#3774FF;" href="${product['link']}" target="_blank">${i18n[lang].use_this_function}</a>
                                                        </div>
                                                        `;
                                                        if(total!=0 || totalM!=0){
                                                            $('.info .app').append(`
                                                                <div class="flex_box" product="${id}">
                                                                    <div class="left">
                                                                        <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                                        <span>${product['name']}</span>
                                                                    </div>
                                                                    <div class="right">
                                                                        ${order_product}
                                                                    </div>
                                                                </div>
                                                            `);
                                                        }
                                                        break;
                                                    case 26801:
                                                        error(i18n[lang].error_26801);
                                                        break;
                                                    case 26802:
                                                        error(i18n[lang].error_26802);
                                                        break;
                                                    case 26803:
                                                        error(i18n[lang].error_26803);
                                                        break;
                                                    case 26804:
                                                        error(i18n[lang].error_26804);
                                                        break;
                                                }
                                            }
                                        ).error(function(xhr){
                                            overtime(26899);
                                        });
                                    }(id))
                                }
                            }else if(product['isFree']==3){
                                if(id==179){
                                    (function(id){
                                        var benefits_ind;
                                        switch(id){
                                            case '179':
                                                benefits_ind=0;
                                                break;
                                        }
                                        var order_product='';
                                        // 查询权益
                                        $.post(
                                            post_url+"benefit/status",
                                            {
                                                e_id:JSON.parse(getCookie('user')).e_id,
                                                t_id:JSON.parse(getCookie('user')).t_id,
                                                token:JSON.parse(getCookie('user')).token,
                                                product_id:id
                                            },function(data){
                                                error_fn(data.error)
                                                console.log(data)
                                                if(productJSON[id][lang]==undefined){
                                                    var product=productJSON[id]['en']
                                                }else{
                                                    var product=productJSON[id][lang]
                                                }
                                                switch(data.error){
                                                    case 0:
                                                        if(id==179){
                                                            order_product+="<div class='scroll_box'>"
                                                        }else{
                                                            order_product+="<p style='font-size:16px;color:#333;margin-bottom:16px;'>"+i18n[lang].hd_downloading+"</p><div class='scroll_box'>"
                                                        }
                                                        $('.info>.right .app>.loading').remove();
                                                        var totalM=0;
                                                        var useM=0;
                                                        var total=0;
                                                        var use=0;
                                                        var islifetime=0;
                                                        for(var i=0;i<data.subscriptions.length;i++){
                                                            try{
                                                                if(data.subscriptions[i].benefits[benefits_ind].cost==undefined){
                                                                    continue;
                                                                }
                                                            }catch(e){
                                                                console.log(e)
                                                                continue;
                                                            }
                                                            if(data.subscriptions[i].expire!=0){
                                                                if(data.subscriptions[i].benefits[benefits_ind].quota*1>data.subscriptions[i].benefits[benefits_ind].cost*1){
                                                                    if(data.subscriptions[i].expire*1>Date.parse(new Date())/1000&&data.subscriptions[i].benefits[benefits_ind].quota*1-data.subscriptions[i].benefits[benefits_ind].cost*1!=0){
                                                                        order_product+=`
                                                                        <table class="isfree2">
                                                                            <tr>
                                                                                <td>${data.subscriptions[i].benefits[benefits_ind].quota} ${i18n[lang].credit}/${i18n[lang].Month} (${getdate(data.subscriptions[i].since)} - ${getdate(data.subscriptions[i].expire)})</td>
                                                                                <td>${i18n[lang].Remaining} <span><i class="num">${data.subscriptions[i].benefits[benefits_ind].quota-data.subscriptions[i].benefits[benefits_ind].cost}</i> ${i18n[lang].credit}</span></td>
                                                                            </tr>
                                                                        </table>
                                                                        `;
                                                                        try{
                                                                            totalM+=data.subscriptions[i].benefits[benefits_ind].quota*1
                                                                            useM+=data.subscriptions[i].benefits[benefits_ind].cost*1
                                                                        }catch(e){
                                                                            console.log(e)
                                                                        }
                                                                    }
                                                                }
                                                            }else{
                                                                console.log(data.subscriptions[i].product_feature)
                                                                if(data.subscriptions[i].product_feature==101&&(data.subscriptions[i].benefits[benefits_ind].quota*1-data.subscriptions[i].benefits[benefits_ind].cost*1)!=0){
                                                                    order_product+=`
                                                                    <table class="isfree2">
                                                                        <tr>
                                                                            <td>${data.subscriptions[i].benefits[benefits_ind].quota*1} ${i18n[lang].credit}/${i18n[lang].lifetime}<i class="free">Free</i></td>
                                                                            <td>${i18n[lang].Remaining} <span><i class="num">${data.subscriptions[i].benefits[benefits_ind].quota*1-data.subscriptions[i].benefits[benefits_ind].cost*1}</i> ${i18n[lang].credit}</span></td>
                                                                        </tr>
                                                                    </table>
                                                                    `;
                                                                }else if(data.subscriptions[i].product_feature==0){
                                                                    
                                                                }else{
                                                                    islifetime=1;
                                                                    try{
                                                                        total+=data.subscriptions[i].benefits[benefits_ind].quota*1
                                                                        use+=data.subscriptions[i].benefits[benefits_ind].cost*1
                                                                    }catch(e){
                                                                        console.log(e)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        if(islifetime&&total-use!=0){
                                                            order_product+=`
                                                            <table class="isfree2">
                                                                <tr>
                                                                    <td>${total} ${i18n[lang].credit}/${i18n[lang].lifetime}</td>
                                                                    <td>${i18n[lang].Remaining} <span><i class="num">${total-use}</i> ${i18n[lang].credit}</span></td>
                                                                </tr>
                                                            </table>
                                                            `;
                                                        }
                                                        if(total-use==0&&totalM-useM==0&&orderList179.length!=0){
                                                            total=productFeature[orderList179[0].plan_id].frequency
                                                            console.log(productFeature[orderList179[0].plan_id])
                                                            order_product+=`
                                                            <table class="isfree2 ${productFeature[orderList179[0].plan_id].time?'':'expire'}">
                                                                <tr>
                                                                    <td>${total} ${i18n[lang].credit}/${productFeature[orderList179[0].plan_id].time?i18n[lang].lifetime:i18n[lang].Month}</td>
                                                                    <td>${i18n[lang].Remaining} <span><i class="num">0</i> ${i18n[lang].credit}</span></td>
                                                                </tr>
                                                            </table>
                                                            `;
                                                        }
                                                        order_product+=`</div>
                                                        <div class="more_box">
                                                            <a class="btn" href="${product['buy-link']}" target="_blank">${i18n[lang].buy_more}</a>
                                                        </div>
                                                        `;
                                                        if(total!=0 || totalM!=0){
                                                            $('.info .app').append(`
                                                                <div class="picwand">
                                                                    <div class="flex_box">
                                                                        <div class="left">
                                                                            <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                                            <span>${product['name']}</span>
                                                                        </div>
                                                                        <div class="right">
                                                                            ${order_product}
                                                                        </div>
                                                                    </div>
                                                                    <div class="product_child">
                                                                        <p>${i18n[lang].Available_for}</p>
                                                                        <div>
                                                                            <div class="top flex_box">
                                                                                <img src="/images/icon/${productJSON['173'][lang].icon}" alt="${productJSON['173'][lang].name}">
                                                                                <div>
                                                                                    <p class="name">${productJSON['173'][lang].name}</p>
                                                                                    <p class="info">${i18n[lang].credit_per.replace(/%s/g,1)}</p>
                                                                                </div>
                                                                                <a href="${productJSON['173'][lang].link}">${i18n[lang].use_immediately}</a>
                                                                            </div>
                                                                            <div class="top flex_box">
                                                                                <img src="/images/icon/${productJSON['182'][lang].icon}" alt="${productJSON['182'][lang].name}">
                                                                                <div>
                                                                                    <p class="name">${productJSON['182'][lang].name}</p>
                                                                                    <p class="info">${i18n[lang].credit_per.replace(/%s/g,2)}</p>
                                                                                </div>
                                                                                <span class="btn">${i18n[lang].use_immediately}
                                                                                <div><!-- <i class="online"></i><span></span> --><i class="win"></i><span></span><i class="mac"></i></div>
                                                                                <div>
                                                                                    <!-- <a href="${productJSON['182'][lang].link}"><i class="online"></i>${i18n[lang].Use_in_browser}</a> -->
                                                                                    <a href="${device_confirm()?'https://download.aiseesoft.com/ai-photo-editor.exe':'https://www.aiseesoft.com/ai-photo-editor/'}"><i class="win"></i>${i18n[lang].win_desktop}</a>
                                                                                    <a href="${device_confirm()?'https://download.aiseesoft.com/mac/ai-photo-editor.dmg':'https://www.aiseesoft.com/ai-photo-editor/'}"><i class="mac"></i>${i18n[lang].Mac_desktop}</a>
                                                                                </div>
                                                                                </span>
                                                                            </div>
                                                                            <div class="top flex_box">
                                                                                <img src="/images/icon/${productJSON['178'][lang].icon}" alt="${productJSON['178'][lang].name}">
                                                                                <div>
                                                                                    <p class="name">${productJSON['178'][lang].name}</p>
                                                                                    <p class="info">${i18n[lang].credit_per.replace(/%s/g,1)}</p>
                                                                                </div>
                                                                                <a href="${productJSON['178'][lang].link}">${i18n[lang].use_immediately}</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            `);
                                                        }
                                                        break;
                                                    case 26801:
                                                        error(i18n[lang].error_26801);
                                                        break;
                                                    case 26802:
                                                        error(i18n[lang].error_26802);
                                                        break;
                                                    case 26803:
                                                        error(i18n[lang].error_26803);
                                                        break;
                                                    case 26804:
                                                        error(i18n[lang].error_26804);
                                                        break;
                                                }
                                            }
                                        ).error(function(xhr){
                                            overtime(26899);
                                        });
                                    }(id))
                                }
                            }
                        $('.warning .all_software').append(`
                            <div>
                                <img width="64" height="64" src="/images/icon/${product['icon']}" loading="lazy" alt="${product['name']}">
                                <p>${product['name']}</p>
                            </div>
                        `);
                    }
                };
                // picwand
                $.ajaxSettings.async=true
                var product_child_arr=[178]
                for(var p=0;p<product_child_arr.length;p++){
                    var product=$('.info>.right .app>div[product$="'+product_child_arr[p]+'"]')
                    // 老套餐没有点数隐藏
                    var isINum=0
                    for(var iNum=0;iNum<product.find('i.num').length;iNum++){
                        console.log(product.find('i.num')[iNum].innerHTML)
                        if(Number(product.find('i.num')[iNum].innerHTML)<=0){
                            isINum=0
                        }else{
                            isINum=1
                            break;
                        }
                    }
                    if(!isINum){
                        product.hide() 
                    }
                }
            };
            break;
        case 24801:
            error(i18n[lang].error_24801);
            break;
    }
}).error(function(xhr){
    overtime(24899);
});
//根据时间戳计算得出有效期还有多久
function surplusTime(expireDate){
    if(expireDate=="0000-00-00 00:00:00"){
        return i18n[lang].lifetime;
    };
    var expireDate=new Date(expireDate);
    var currentDate=Date.parse(new Date());
    if(parseInt((expireDate-currentDate)/(1000*60*60*24))<=15){
        return '<span style="color: #D80303;">'+i18n[lang].remain+' '+parseInt((expireDate-currentDate)/(1000*60*60*24))+' '+i18n[lang].day_s+'</span>';
    }else{
        return i18n[lang].remain+" "+parseInt((expireDate-currentDate)/(1000*60*60*24))+" "+i18n[lang].day_s;
    }
}
// 时间戳转换
function getdate(time) {
    var now=new Date(time*1000)
    var y=now.getFullYear()
    var m=now.getMonth()+1
    var d=now.getDate()
    return (d < 10 ? "0" + d : d) + "/" + (m < 10 ? "0" + m : m) + "/" + y
}